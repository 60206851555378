import React, { Component } from 'react';

/**
 * Search Modal
 * Modal that appears when search button is pressed in Navbar
 */
class Search extends Component {

    constructor(props) {
        super(props);
        this.state = { searchText: "" };
    }

    textUpdate = (event) => this.setState({ searchText: event.target.value });

    clear = () => {
        this.setState({ searchText: "" });
        this.props.searchRushees("");
    };

    searchRushees = () => this.props.searchRushees(this.state.searchText);

    render() {
        return (
            <div>
                <div id="search" className="modal bottom-sheet">
                    <div className="modal-content container">
                        <div className="blue-input-field">
                            <input placeholder="Search Rushees" type="text" value={this.state.searchText} onChange={this.textUpdate}/>
                        </div>
                        <div className="modal-footer">
                            <div className="row">
                                <button onClick={this.clear} className="col s6 m6 l6 center modal-close btn-flat no-active">Clear</button>
                                <button onClick={this.searchRushees} className="col s6 m6 l6 center modal-close btn-flat no-active">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Search;
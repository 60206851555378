// Bid Status Enum
const BidStatus = {
    ACCEPTED: "accepted",
    VOTED: "bid-voted",
    RECEIVED: "bid-received",
    ELSEWHERE: "accepted-elsewhere",
    TALK: "the-talk",
    NONE: "none"
};

export default BidStatus;
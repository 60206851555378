import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import AddNote from '../components/AddNote';
import Vote from '../components/Vote';
import Loading from '../components/Loading';
import firebase from '../Firebase';
import isAdmin from '../util/isAdmin';
import BidStatus from "../util/BidStatus";
import Page from "../util/Page";

/**
 * Page to View Rushee Information
 * Uses gtid from URL to get information
 */
class ViewRushee extends Component {

    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection("AllRushees");
        this.storageRef = firebase.app().storage().ref("RusheePictures");
        this.unsubscribe = null;
        this.user = {};
        // Authenticate user and assign admin status if needed, Login Screen if not logged in
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.user.email = user.email;
                this.user.isAdmin = isAdmin(user.email)
            } else {
                this.props.history.push('/');
            }
        });
        this.state = { loading: true };
        this._isMounted = false;
    }

    /**
     * Stops loading after picture is loaded
     */
    loadPic = () => {
        if (!this._isMounted) { return }
        this.setState({ loading: false });
    };

    onCollectionUpdate = () => {
        this.ref.doc("Rushees").get().then(doc => {
            if (!this._isMounted) { return }
            Object.values(doc.data()).forEach(rushee => {
                if (this.props.match.params.gtid === rushee.gtid) {
                    let newStatus;
                    switch (rushee.bidStatus) {
                        case BidStatus.NONE:
                            newStatus = "None";
                            break;
                        case BidStatus.ACCEPTED:
                            newStatus = "Accepted";
                            break;
                        case BidStatus.VOTED:
                            newStatus = "Voted";
                            break;
                        case BidStatus.RECEIVED:
                            newStatus = "Received";
                            break;
                        case BidStatus.ELSEWHERE:
                            newStatus = "Elsewhere";
                            break;
                        case rushee.bidStatus === BidStatus.TALK:
                            newStatus = "Talk";
                            break;
                        default:
                            newStatus = "None";
                    }
                    this.storageRef.child(rushee.pictureID).getDownloadURL().then(url => {
                        rushee.picURL = url;
                        const pic = new Image();
                        pic.onload = () => this.loadPic();
                        pic.src = rushee.picURL;
                    });
                    let newNumber = rushee.phoneNumber.toString();
                    newNumber = `(${newNumber.substring(0, 3)}) ${newNumber.substring(3, 6)} - ${newNumber.substring(6)}`;
                    rushee.phoneNumber = newNumber;
                    this.setState({
                        rushee: rushee,
                        bidStatus: newStatus,
                    });
                }
            });
        });
    };

    componentDidMount() {
        this._isMounted = true;
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    render() {
        const rushee = this.state.rushee;
        return (
            <div>
                <Navbar config={{
                    home: true,
                    back: true,
                    backPage: Page.HOMEPAGE,
                    addEvent: !this.state.loading,
                    edit: !this.state.loading
                    }}
                    rushee={rushee}
                />
                {this.state.loading ? <Loading/> :
                    <div>
                        <div className="container">
                            <div className="row center">
                            </div>
                            <div className="row center">
                                <h2 className={"center view-name" + (rushee.bidStatus && " view-" + rushee.bidStatus)}>{rushee.firstName + " " + rushee.lastName}</h2>
                                {rushee.am && <h4 className={"center view-am" + (rushee.bidStatus && " view-" + rushee.bidStatus)}>{"AM " + rushee.am}</h4>}
                                <img className={"profile-image " + rushee.bidStatus} src={rushee.picURL} alt="Rushee"/>
                            </div>
                            <div className="row center">
                                <table className="centered responsive-table">
                                    <thead>
                                        <tr>
                                            <th>Major</th>
                                            <th>Hometown</th>
                                            <th>Year</th>
                                            <th>Phone #</th>
                                            <th>Bid Status</th>
                                            <th>Contact</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.rushee.major}</td>
                                            <td>{this.state.rushee.city + ", " + this.state.rushee.state}</td>
                                            <td>{this.state.rushee.year}</td>
                                            <td>{this.state.rushee.phoneNumber}</td>
                                            <td>{this.state.bidStatus}</td>
                                            <td>{this.state.rushee.contact}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <table className="centered">
                                    <thead>
                                        <tr>
                                            <th>Faith</th>
                                            <th>Fratiness</th>
                                            <th>Friendliness</th>
                                            <th>Met</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{rushee.faith.length}</td>
                                            <td>{rushee.met.includes(this.user.email) ?
                                                (Object.keys(rushee.fratiness).reduce((sum, key) => sum+rushee.fratiness[key], 0))/(Object.keys(rushee.fratiness).length) : "--"}</td>
                                            <td>{rushee.met.includes(this.user.email) ?
                                                (Object.keys(rushee.friendliness).reduce((sum, key) => sum+rushee.friendliness[key], 0))/(Object.keys(rushee.friendliness).length) : "--"}</td>
                                            <td>{rushee.met.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row center">
                                {Object.entries(rushee.notes).map(item => <p key={item[0]}>{item[1]}</p>)}
                            </div>
                        </div>
                        <div className="center">
                            <a className="bottom-button waves-effect waves-light btn blue modal-trigger" href="#addNote">Add Note</a>
                            <a className="bottom-button waves-effect waves-light btn blue modal-trigger" href="#vote">Vote</a>
                        </div>
                        <Vote gtid={rushee.gtid} user={this.user}/>
                        <AddNote gtid={rushee.gtid} user={this.user}/>
                    </div>
                    }
            </div>
        );
    }
}

export default ViewRushee;
import React, { Component } from 'react';
import firebase from '../Firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Navbar from '../components/Navbar';

/**
 * Login Page
 * Used to Login through Firebase Authentication
 */
class Login extends Component {

    constructor(props) {
        super(props);
        this.auth = firebase.auth();
        this.uiConfig = {
            signInFlow: "popup",
            signInOptions: [{
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                scopes: [
                    'https://www.googleapis.com/auth/contacts.readonly'
                ],
                customParameters: {
                    prompt: 'select_account',
                    hd: 'gttx.org'
                }
            }],
            signInSuccessUrl: "/HomePage"
        }
    }

    render() {
        return (
            <div>
                <Navbar config={{empty: true}}/>
                <h2 className="center blue-text">Baruki</h2>
                <h4 className="center blue-text">Theta Xi Rush App</h4>
                <div className="center blue-text">Please Sign In</div>
                <br/>
                <StyledFirebaseAuth className="center"
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                />
            </div>
        );
    }
}

export default Login;
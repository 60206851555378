import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/**
 * Rushee Card for HomePage
 * Holds Basic Rushee Information
 */
class RusheeCard extends Component {

    render() {
        const rushee = this.props.rushee;
        return (
            <div>
                <div className="col s6 m3 l2">
                    <div className={`card ${rushee.bidStatus}`}>
                        <Link to={{
                            pathname: `/${rushee.gtid}/ViewRushee`,
                            state: {
                                rushee: rushee,
                                user: this.props.user
                            }
                        }}
                        >
                            <div className="card-image">
                                <img className="card-image-size s6 m3 l2" src={rushee.picURL} alt="Rushee"/>
                            </div>
                            <div className="card-content">
                                <div>
                                    <span className="card-title activator grey-text text-darken-4 center">{rushee.firstName} {rushee.lastName}</span>
                                    <p className="blue-text center">{rushee.year} | {rushee.major} | {rushee.city}, {rushee.state}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default RusheeCard;
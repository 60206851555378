import React, { Component } from 'react';
import UnicornLogo from '../assets/LoadingUnicorn.png';

/**
 * Loading Icon
 * Used when data (such as images) are loading
 */
class Loading extends Component {

    render() {
        return (
            <div>
                <img className="loading-icon" src={UnicornLogo} alt="Unicorn"/>
            </div>
        );
    }
}

export default Loading;
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const settings = {};

const config = {
  apiKey: "AIzaSyDfetsm6dJAWL0ytPqwyABwoDiGcXyu6xM",
  authDomain: "rush-6de5c.firebaseapp.com",
  databaseURL: "https://rush-6de5c.firebaseio.com",
  projectId: "rush-6de5c",
  storageBucket: "rush-6de5c.appspot.com",
  messagingSenderId: "524507745351",
  appId: "1:524507745351:web:96fbb0e0feb79f9ccdf562"
};

firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;
import React, { Component } from 'react';
import firebase from '../Firebase';
import M from 'materialize-css';

/**
 * Vote Modal
 * Modal that appears when the Vote button is pressed in ViewRushee.js
 */
class Vote extends Component {

    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection("AllRushees");
        this.unsubscribe = null;
        this._isMounted = false;
        this.state = {
            faith: false,
            fratiness: 5,
            friendliness: 5,
            gtid: this.props.gtid,
            user: this.props.user
        };
    }

    onCollectionUpdate = () => {
        this.ref.doc("Rushees").get().then(doc => {
            if (!this._isMounted) { return }
            let rushee = doc.data()[this.state.gtid];
            this.setState({
                rushee: rushee,
                faith: rushee.faith.includes(this.state.user.email),
                fratiness: rushee.fratiness[this.state.user.email] != null ? rushee.fratiness[this.state.user.email] : 5,
                friendliness: rushee.friendliness[this.state.user.email] != null ? rushee.friendliness[this.state.user.email] : 5
            });
        });
    };
    
    componentDidMount() {
        this._isMounted = true;
        const array_of_dom_elements = document.querySelectorAll("input[type=range]");
        M.Range.init(array_of_dom_elements);
        M.AutoInit();
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    /**
     * Updates state for faith, fratiness, and friendliness
     */
    updateVote = (event) => {
        if (event.target.id === "faith") {
            this.setState({ faith: event.target.checked});
        } else if (event.target.id === "fratiness") {
            this.setState({ fratiness: parseInt(event.target.value)});
        } else if (event.target.id === "friendliness") {
            this.setState({ friendliness: parseInt(event.target.value)});
        }
    };

    /**
     * Submits votes on Rushee
     */
    vote = () => {
        let rushee = this.state.rushee;
        const { email } = this.state.user;
        let met = rushee.met;
        let faith = rushee.faith;
        let fratiness = rushee.fratiness;
        let friendliness = rushee.friendliness;
        // Adds user to Met if voting for first time
        if (!met.includes(this.state.user.email)) {
            met.push(email);
        }
        // Adds user to Faith if faith is checked and user has not already said yes to faith;
        if (this.state.faith && !faith.includes(email)) {
            faith.push(email);
        // If you voted no to faith but have said yes before, you are removed from Faith
        } else if (!this.state.faith && faith.includes(email)) {
            faith.splice(faith.indexOf(email), 1);
        }
        fratiness[this.state.user.email] = this.state.fratiness;
        friendliness[this.state.user.email] = this.state.friendliness;
        this.ref.doc("Rushees").update({ [rushee.gtid]: rushee });
    };

    render() {
        return (
            <div id="vote" className="modal bottom-sheet">
                <div className="modal-content">
                    <h4 className="center blue-text">Vote</h4>
                    <form className="col s12 m12 l12">
                        <div className="row">
                            <div className="col s4 m4 l4 center">
                                <div className="center">
                                    <p className="button-label">Faith</p>
                                </div>
                                <p>
                                    <label>
                                        <input type="checkbox" className="faithbox filled-in" id="faith" checked={this.state.faith} onChange={this.updateVote}/>
                                        <span>Faith?</span>
                                    </label>
                                </p>
                            </div>
                            <div className="col s4 m4 l4 center">
                                <div className="center">
                                    <p className="slider-label">Fratiness</p>
                                </div>
                                <p className="range-field blue-range-field">
                                    <input type="range" min="0" max="10" className="blue" id="fratiness" value={this.state.fratiness} onChange={this.updateVote}/>
                                </p>
                            </div>
                            <div className="col s4 m4 l4 center">
                                <div className="center">
                                    <p className="slider-label">Friendliness</p>
                                </div>
                                <p className="range-field blue-range-field">
                                    <input type="range" min="0" max="10" id="friendliness" value={this.state.friendliness} onChange={this.updateVote}/>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button className="modal-close waves-effect btn-flat">Cancel</button>
                    <button className="modal-close waves-effect btn-flat" onClick={this.vote}>Vote</button>
                </div>
            </div>
        );
    }
}

export default Vote;

import React, { Component } from 'react';
import SortCriteria from "../util/SortCriteria";

/**
 * Sort Modal
 * Modal that appears when Sort button is pressed in Navbar
 */
class Sort extends Component {

    constructor(props) {
        super(props);
        this.state = { sortCriteria: SortCriteria.FIRSTNAME };
    }

    updateSort = (newSort) => {
        this.setState({ sortCriteria: newSort });
    };

    sort = () => this.props.sortRushees(this.state.sortCriteria);

    render() {
        return (
            <div>
                <div id="sort" className="modal bottom-sheet">
                    <div className="modal-content">
                        <ul className="collection">
                            <li onClick={() => this.updateSort(SortCriteria.FIRSTNAME)}
                                className={"collection-item" + ((this.state.sortCriteria === SortCriteria.FIRSTNAME) ? " active" : "")}>First Name</li>
                            <li onClick={() => this.updateSort(SortCriteria.LASTNAME)}
                                className={"collection-item" + ((this.state.sortCriteria === SortCriteria.LASTNAME) ? " active" : "")}>Last Name</li>
                            <li onClick={() => this.updateSort(SortCriteria.BIDSTATUS)}
                                className={"collection-item" + ((this.state.sortCriteria === SortCriteria.BIDSTATUS) ? " active" : "")}>Bid Status</li>
                            <li onClick={() => this.updateSort(SortCriteria.AM)}
                                className={"collection-item" + ((this.state.sortCriteria === SortCriteria.AM) ? " active" : "")}>AM</li>
                            <li onClick={() => this.updateSort(SortCriteria.GTID)}
                                className={"collection-item" + ((this.state.sortCriteria === SortCriteria.GTID) ? " active" : "")}>GTID</li>
                        </ul>
                        <div className="modal-footer">
                            <button onClick={this.sort} className="modal-close btn-flat no-active">Sort</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sort;
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from "./pages/Login";
import HomePage from './pages/HomePage';
import ViewRushee from './pages/ViewRushee';
import EditRushee from './pages/EditRushee';
import AddRushee from './pages/AddRushee';
import Page from "./util/Page";
import './index.css';

ReactDOM.render(
    <div>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path={`/${Page.HOMEPAGE}`} component={HomePage} />
                <Route path={`/${Page.ADDRUSHEE}`} component={AddRushee} />
                <Route path={`/:gtid/${Page.VIEWRUSHEE}`} component={ViewRushee} />
                <Route path={`/:gtid/${Page.EDITRUSHEE}`} component={EditRushee} />
            </Switch>
        </BrowserRouter>

    </div>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

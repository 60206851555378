import React, { Component } from 'react';
import firebase from '../Firebase';
import M from 'materialize-css';

/**
 * Add Note Modal
 * Modal that appears when the Add Note button is pressed in ViewRushee.js
 */
class AddNote extends Component {

    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection("AllRushees");
        this._isMounted = false;
        this.state = {
            noteText: "",
            gtid: this.props.gtid,
            user: this.props.user
        };
    }

    textUpdate = (event) => {
        this.setState({ noteText: event.target.value });
    };

    addNote = () => {
        let rushee = this.state.rushee;
        const { email } = this.state.user;
        let notes = rushee.notes;
        notes[email] = this.state.noteText;
        this.ref.doc("Rushees").update({ [rushee.gtid]: rushee });
    };

    onCollectionUpdate = () => {
        this.ref.doc("Rushees").get().then(doc => {
            if (!this._isMounted) { return }
            let rushee = doc.data()[this.state.gtid];
            this.setState({
                rushee: rushee,
                noteText: rushee.notes[this.state.user.email]
            });
        });
    };

    componentDidMount() {
        this._isMounted = true;
        M.AutoInit();
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    render() {
        return (
            <div id="addNote" className="modal bottom-sheet">
                <div className="modal-content">
                    <h4 className="center blue-text">Add Note</h4>
                    <label>Please make sure to not add repetitive information, and to keep it short and consise. You can add further details in post rush if necessary.</label>
                    <form className="col s12 m12 l12">
                        <div className="row notes-textbox">
                            <div className="input-field col s12 blue-textarea">
                                <textarea className="materialize-textarea" onChange={this.textUpdate} value={this.state.noteText}/>
                                <label className={this.state.noteText && "active"}>Note</label>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button className="modal-close waves-effect btn-flat">Cancel</button>
                    <button className="modal-close waves-effect btn-flat" onClick={this.addNote}>Add Note</button>
                </div>
            </div>
        );
    }
}

export default AddNote;
/**
 * Determines if Rushee Info is valid for creation/updating
 * STILL NEED TO CHECK FOR GTID UNIQUENESS!
 * @param info All of the rushee info (this.state usually)
 * @returns {boolean}
 */
function isValidInfo(info) {
    return (info.firstName && info.lastName && info.major && info.gtid.length === 9 &&
        !isNaN(parseInt(info.gtid)) && info.phoneNumber.length === 10 && !isNaN(parseInt(info.phoneNumber)) &&
        info.city && info.state);
}

export default isValidInfo
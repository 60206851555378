import React, { Component } from 'react';
import BidStatus from "../util/BidStatus";

/**
 * Filter Modal
 * Modal that appears when filter button is pressed in Navbar
 */
class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = { filter: BidStatus.NONE };
    }

    updateFilter = (newFilter) => {
        this.setState({ filter: newFilter });
    };

    filter = () => this.props.filterRushees(this.state.filter);

    render() {
        return (
            <div>
                <div id="filter" className="modal bottom-sheet">
                    <div className="modal-content">
                        <ul className="collection">
                            <li onClick={() => this.updateFilter(BidStatus.NONE)}
                                className={"collection-item" + ((this.state.filter === BidStatus.NONE) ? " active" : "")}>None</li>
                            <li onClick={() => this.updateFilter(BidStatus.VOTED)}
                                className={"collection-item" + ((this.state.filter === BidStatus.VOTED) ? " active" : "")}>Voted</li>
                            <li onClick={() => this.updateFilter(BidStatus.RECEIVED)}
                                className={"collection-item" + ((this.state.filter === BidStatus.RECEIVED) ? " active" : "")}>Received</li>
                            <li onClick={() => this.updateFilter(BidStatus.ACCEPTED)}
                                className={"collection-item" + ((this.state.filter === BidStatus.ACCEPTED) ? " active" : "")}>Accepted</li>
                            <li onClick={() => this.updateFilter(BidStatus.ELSEWHERE)}
                                className={"collection-item" + ((this.state.filter === BidStatus.ELSEWHERE) ? " active" : "")}>Elsewhere</li>
                            <li onClick={() => this.updateFilter(BidStatus.TALK)}
                                className={"collection-item" + ((this.state.filter === BidStatus.TALK) ? " active" : "")}>Talk</li>
                        </ul>
                        <div className="modal-footer">
                            <button onClick={this.filter} className="modal-close btn-flat no-active">Filter</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Filter;
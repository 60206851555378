import React, { Component } from 'react';
import firebase from '../Firebase';
import Navbar from '../components/Navbar';
import RusheeCard from '../components/RusheeCard';
import Filter from '../components/Filter';
import Search from '../components/Search';
import Sort from '../components/Sort';
import Loading from '../components/Loading';
import isAdmin from '../util/isAdmin';
import SortCriteria from "../util/SortCriteria";
import BidStatus from "../util/BidStatus";

/**
 * Home Page
 */
class HomePage extends Component {

    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection("AllRushees");
        this.storageRef = firebase.app().storage().ref("RusheePictures");
        this.unsubscribe = null;
        this.user = {};
        // Authenticate user and assign admin status if needed, Login Screen if not logged in
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.user.email = user.email;
                this.user.isAdmin = isAdmin(user.email)
            } else {
                this.props.history.push('/');
            }
        });
        this._isMounted = false;
        this.state = {
            rushees: [],
            filteredRushees: [],
            filtered: false,
            loading: true,
            totalPicsLoaded: 0,
            sortCriteria: SortCriteria.FIRSTNAME
        };
    }

    /**
     * Called when a picture is loaded
     * Loading is complete when all Rushee pictures are loaded
     */
    loadPic = () => {
        if (!this._isMounted) { return }
        this.setState({totalPicsLoaded: this.state.totalPicsLoaded + 1});
        // Checks if (for filtered and unfiltered) all pics are loaded
        if ((!this.state.filtered && this.state.totalPicsLoaded === this.state.rushees.length) ||
            (this.state.filtered && this.state.totalPicsLoaded === this.state.filteredRushees.length)) {
            this.sortRushees(this.state.sortCriteria);
            this.setState({
                loading: false,
                totalPicsLoaded: 0
            });
        }
    };

    /**
     * Called when the search button is pressed
     * Filters rushees based on search text
     */
    searchRushees = (searchText) => {
        if (!searchText) {
            this.setState({ filtered: false });
        } else {
            let filtered = this.state.rushees.filter(rushee => {
                let fullName = rushee.firstName + " " + rushee.lastName;
                return fullName.toLowerCase().includes(searchText.toLowerCase());
            });
            this.setState({
                filteredRushees: filtered,
                filtered: true
            });
        }
    };

    /**
     * Called when the filter button is pressed
     * Filters rushees based on filter
     */
    filterRushees = (filter) => {
        if (filter === "none") {
            this.setState({ filtered: false, loading: false });
        } else {
            let filtered = this.state.rushees.filter(rushee => rushee.bidStatus === filter);
            this.setState({
                filteredRushees: filtered,
                filtered: true
            });
        }
    };

    /**
     * Called when the sort button is pressed
     * Sorts rushees based on sortCriteria
     */
    sortRushees = (sortCriteria) => {
        switch (sortCriteria) {
            case SortCriteria.FIRSTNAME:
                this.state.rushees.sort((a, b) =>
                    (a.firstName.toLowerCase() + a.lastName.toLowerCase() > b.firstName.toLowerCase() + b.lastName.toLowerCase()) ? 1 : -1);
                this.state.filteredRushees.sort((a, b) =>
                    (a.firstName.toLowerCase() + a.lastName.toLowerCase() > b.firstName.toLowerCase() + b.lastName.toLowerCase()) ? 1 : -1);
                this.setState({ sortCriteria: SortCriteria.FIRSTNAME });
                break;
            case SortCriteria.LASTNAME:
                this.state.rushees.sort((a, b) =>
                    (a.lastName.toLowerCase() + a.firstName.toLowerCase() > b.lastName.toLowerCase() + b.firstName.toLowerCase()) ? 1 : -1);
                this.state.filteredRushees.sort((a, b) =>
                    (a.lastName.toLowerCase() + a.firstName.toLowerCase() > b.lastName.toLowerCase() + b.firstName.toLowerCase()) ? 1 : -1);
                this.setState({ sortCriteria: SortCriteria.LASTNAME });
                break;
            case SortCriteria.BIDSTATUS:
                let bidSort = {
                    [BidStatus.ACCEPTED]: 3,
                    [BidStatus.VOTED]: 1,
                    [BidStatus.RECEIVED]: 2,
                    [BidStatus.ELSEWHERE]: 4,
                    [BidStatus.TALK]: 5,
                    [BidStatus.NONE]: 0
                };
                let bidStatusSort = (a, b) => {
                    if (a.bidStatus === BidStatus.ACCEPTED && b.bidStatus === BidStatus.ACCEPTED) {
                        return (a.am > b.am) ? 1 : -1;
                    }
                    return (bidSort[a.bidStatus] > bidSort[b.bidStatus]) ? 1 : -1;
                };
                this.state.rushees.sort(bidStatusSort);
                this.state.filteredRushees.sort(bidStatusSort);
                this.setState({ sortCriteria: SortCriteria.BIDSTATUS });
                break;
            case SortCriteria.AM:
                let amSort = (a, b) => {
                    if (a.am !== null && b.am !== null) {
                        return (a.am > b.am) ? 1 : -1;
                    } else if (a.am !== null && b.am === null) {
                        return -1;
                    } else if (a.am === null && b.am !== null) {
                        return 1;
                    }
                    return 0;
                };
                this.state.rushees.sort(amSort);
                this.state.filteredRushees.sort(amSort);
                this.setState({ sortCriteria: SortCriteria.AM });
                break;
            case SortCriteria.GTID:
                this.state.rushees.sort((a, b) => (parseInt(a.gtid) - parseInt(b.gtid)));
                this.state.filteredRushees.sort((a, b) => (parseInt(a.gtid) - parseInt(b.gtid)));
                this.setState({ sortCriteria: SortCriteria.GTID });
                break;
            default:
                break;
        }
    };

    /**
     * Update Rushee Info
     * Loads all pictures for rushees as well (uses loadPic())
     */
    onCollectionUpdate = () => {
        this.ref.doc("Rushees").get().then(doc => {
            if (!this._isMounted) { return }
            const allRushees = Object.values(doc.data());
            for (let j = 0; j < allRushees.length; j++) {
                const i = j;
                this.storageRef.child(allRushees[i].pictureID).getDownloadURL().then(url => {
                    if (!this._isMounted) { return }
                    allRushees[i].picURL = url;
                    const pic = new Image();
                    pic.onload = () => this.loadPic();
                    pic.src = url;
                    this.setState({ rushees: allRushees });
                });
            }
        });
    };

    componentDidMount() {
        this._isMounted = true;
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    render() {
        return (
            <div>
                <Navbar config={{
                    home: true,
                    addRushee: true,
                    sort: true,
                    filter: true,
                    search: true
                    }}
                    user={this.user}
                />
                <div className="container">
                    <h2 className="header center blue-text">Rushees</h2>
                </div>
                {(this.state.filtered && this.state.filteredRushees.length === 0) && <h3 className="header center blue-text">No Results</h3>}
                {this.state.loading ? <Loading/> :
                <div className="row">
                    {this.state.filtered ?
                        this.state.filteredRushees.map(rushee => <RusheeCard rushee={rushee} key={rushee.gtid} user={this.user}/>) :
                        this.state.rushees.map(rushee => <RusheeCard rushee={rushee} key={rushee.gtid} user={this.user}/>)
                    }
                </div>}
                <Search searchRushees={this.searchRushees}/>
                <Filter filterRushees={this.filterRushees}/>
                <Sort sortRushees={this.sortRushees}/>
            </div>
        );
    }
}

export default HomePage;
import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import firebase from '../Firebase';
import isAdmin from '../util/isAdmin';
import isValidInfo from "../util/isValidInfo";
import Page from "../util/Page";

/**
 * Page to Add Rushee
 */
class AddRushee extends Component {

    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection("AllRushees");
        this.storageRef = firebase.app().storage().ref("RusheePictures");
        this.unsubscribe = null;
        this.user = {};
        // Authenticate user and assign admin status if needed, Login Screen if not logged in
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.user.email = user.email;
                this.user.isAdmin = isAdmin(user.email)
            } else {
                this.props.history.push('/');
            }
        });
        this._isMounted = false;
        this.state = {
            firstName: "",
            lastName: "",
            year: "1st",
            major: "",
            gtid: "",
            phoneNumber: "",
            city: "",
            state: "",
            picture: null,
            pictureID: ""
        }
    }

    addRushee = () => {
        // If the info is valid and GTID is unique, then update
        if (!isValidInfo(this.state) || this.state.gtids.includes(this.state.gtid)) {
            alert("Make sure all info is filled in and in correct format!");
            return;
        }
        const rushee = {
            [this.state.gtid]: {
                am: null,
                bidStatus: "none",
                city: this.state.city,
                contact: "",
                faith: [],
                firstName: this.state.firstName,
                fratiness: {},
                friendliness: {},
                gtid: this.state.gtid,
                lastName: this.state.lastName,
                major: this.state.major,
                met: [],
                notes: {},
                phoneNumber: this.state.phoneNumber,
                pictureID: this.state.picture ? this.state.firstName.toLowerCase() + this.state.lastName.toLowerCase() + ".jpeg" : "default.jpeg",
                state: this.state.state,
                year: this.state.year
            }
        };
        let picUpload;
        if (this.state.picture) {
            picUpload = this.storageRef.child(this.state.firstName.toLowerCase() + this.state.lastName.toLowerCase() + ".jpeg")
                .put(this.state.picture);
        }
        let rusheeUpload = this.ref.doc("Rushees").update(rushee);
        // After pic and rushee are uploaded, go to Home Page
        Promise.allSettled([picUpload, rusheeUpload]).then(this.props.history.push('/HomePage'));
    };

    updateInfo = (event) => {
        if (event.target.type === "file") {
            this.setState({picture: event.target.files[0]});
        }
        this.setState({ [event.target.id]: event.target.value });
    };

    /**
     * Update Rushee GTIDs (to check for uniqueness)
     */
    onCollectionUpdate = () => {
        this.ref.doc("Rushees").get().then(doc => {
            if (!this._isMounted) { return }
            this.setState({ gtids: Object.keys(doc.data()) });
        });
    };

    componentDidMount() {
        this._isMounted = true;
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    render() {
        return (
            <div>
                <Navbar config={{
                    home: true,
                    back: true,
                    backPage: Page.HOMEPAGE
                }}/>
                <div className="container">
                    <h2 className="header center blue-text">Add Rushee</h2>
                </div>
                <div className="container">
                    <form className="row">
                        <div className="input-field col s6 blue-input-field">
                            <input id="firstName" type="text" className="validate" pattern=".{1,}" required value={this.state.firstName} onChange={this.updateInfo}/>
                            <label htmlFor="firstName">First</label>
                            <span className="helper-text" data-error="Can not be empty"/>
                        </div>
                        <div className="input-field col s6 blue-input-field">
                            <input id="lastName" type="text" className="validate" pattern=".{1,}" required value={this.state.lastName} onChange={this.updateInfo}/>
                            <label htmlFor="lastName">Last</label>
                            <span className="helper-text" data-error="Can not be empty"/>
                        </div>
                        <div className="input-field col s6 blue-input-field">
                            <select id="year" value={this.state.year} onChange={this.updateInfo}>
                                <option value="1st">1st Year</option>
                                <option value="2nd">2nd Year</option>
                                <option value="3rd">3rd Year</option>
                                <option value="4th">4th Year</option>
                            </select>
                            <label>Year</label>
                        </div>
                        <div className="input-field col s6 blue-input-field">
                            <input id="major" type="text" className="validate" pattern=".{1,}" required value={this.state.major} onChange={this.updateInfo}/>
                            <label htmlFor="major">Major</label>
                            <span className="helper-text" data-error="Can not be empty"/>
                        </div>
                        <div className="input-field col s6 blue-input-field">
                            <input id="gtid" type="text" className="validate" pattern=".{9}" required value={this.state.gtid} onChange={this.updateInfo}/>
                            <label htmlFor="gtid">GTID</label>
                            <span className="helper-text" data-error="Must be a 9-digit number"/>
                        </div>
                        <div className="input-field col s6 blue-input-field">
                            <input id="phoneNumber" type="text" className="validate" pattern=".{10}" required value={this.state.phoneNumber} onChange={this.updateInfo}/>
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <span className="helper-text" data-error="Must be a 10-digit number"/>
                        </div>
                        <div className="input-field col s6 blue-input-field">
                            <input id="city" type="text" className="validate" pattern=".{1,}" required value={this.state.city} onChange={this.updateInfo}/>
                            <label htmlFor="city">City</label>
                            <span className="helper-text" data-error="Can not be empty"/>
                        </div>
                        <div className="input-field col s6 blue-input-field">
                            <input id="state" type="text" className="validate" pattern=".{1,}" required value={this.state.state} onChange={this.updateInfo}/>
                            <label htmlFor="state">State</label>
                            <span className="helper-text" data-error="Can not be empty"/>
                        </div>
                        <div className="file-field input-field col s12 blue-input-field" onChange={this.updateInfo}>
                            <div className="btn blue">
                                <span>Picture</span>
                                <input type="file" id="pictureID"/>
                            </div>
                            <div className="file-path-wrapper blue-input-field">
                                <input className="file-path" type="text" value={this.state.pictureID} onChange={this.updateInfo} placeholder="Upload Picture"/>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="center">
                    <button className="waves-effect waves-light btn blue" onClick={this.addRushee}>Add Rushee</button>
                </div>
            </div>
        );
    }
}

export default AddRushee;
// SortCriteria Criteria Enum
const SortCriteria = {
    FIRSTNAME: "firstName",
    LASTNAME: "lastName",
    BIDSTATUS: "bidStatus",
    AM: "am",
    GTID: "gtid"
};

export default SortCriteria;


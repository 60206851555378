import React, { Component } from 'react';
import firebase from '../Firebase';
import M from 'materialize-css';
import Loading from "../components/Loading";
import Navbar from '../components/Navbar';
import isAdmin from '../util/isAdmin';
import isValidInfo from "../util/isValidInfo";
import BidStatus from "../util/BidStatus";
import Page from "../util/Page";

/**
 * Page to Edit Rushee Information
 * Uses gtid from URL to get information
 */
class EditRushee extends Component {

    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection("AllRushees");
        this.storageRef = firebase.app().storage().ref("RusheePictures");
        this.unsubscribe = null;
        this.user = {};
        // Authenticate user and assign admin status if needed, Login Screen if not logged in
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.user.email = user.email;
                this.user.isAdmin = isAdmin(user.email);
            } else {
                this.props.history.push('/');
            }
        });
        this._isMounted = false;
        this.state = {
            loading: true,
            picture: null
        };
    };

    updateRushee = () => {
        // If the info is valid and GTID is either not changed/unique, then update
        if (!isValidInfo(this.state) || !((this.state.oldgtid === this.state.gtid) || !this.state.gtids.includes(this.state.gtid))) {
            alert("Make sure all info is filled in and in correct format!");
            return;
        }
        const rushee = {
            [this.state.gtid]: {
                am: this.state.am ? parseInt(this.state.am) : null,
                bidStatus: this.state.bidStatus,
                city: this.state.city,
                contact: this.state.contact,
                faith: this.state.faith,
                firstName: this.state.firstName,
                fratiness: this.state.fratiness,
                friendliness: this.state.friendliness,
                gtid: this.state.gtid,
                lastName: this.state.lastName,
                major: this.state.major,
                met: this.state.met,
                notes: this.state.notes,
                phoneNumber: this.state.phoneNumber,
                pictureID: this.state.pictureID !== "default.jpeg" ? this.state.firstName.toLowerCase() + this.state.lastName.toLowerCase() + ".jpeg" : "default.jpeg",
                state: this.state.state,
                year: this.state.year
            }
        };
        let oldRusheeDelete;
        if (this.state.oldgtid !== this.state.gtid) {
            oldRusheeDelete = this.ref.doc("Rushees").update({ [this.state.oldgtid]: firebase.firestore.FieldValue.delete()});
        }
        let oldPicDelete;
        if (this.state.pictureID !== "default.jpeg" && (this.state.firstName !== this.state.oldFirst || this.state.lastName !== this.state.oldLast)) {
            oldPicDelete = this.storageRef.child(this.state.oldFirst.toLowerCase() + this.state.oldLast.toLowerCase() + ".jpeg").delete();
        }
        let picUpdate;
        if (this.state.pictureID !== "default.jpeg") {
            picUpdate = this.storageRef.child(this.state.firstName.toLowerCase() + this.state.lastName.toLowerCase() + ".jpeg")
                .put(this.state.picture);
        }
        let rusheeUpdate = this.ref.doc("Rushees").update(rushee);
        // After pic and rushee are updated and old pic and old rushee are deleted, go to Home Page
        Promise.allSettled([picUpdate, rusheeUpdate, oldPicDelete, oldRusheeDelete]).then(() => this.props.history.push('/HomePage'));
    };

    updateInfo = (event) => {
        if (event.target.type === "file") {
            let pic = event.target.files[0];
            this.setState({ picture: pic });
        }
        // Reset AM if bid status is changed from Accepted
        if (event.target.id === "bidStatus" && event.target.value !== "accepted") {
            this.setState({ am: "" })
        }
        this.setState({ [event.target.id]: event.target.value });
    };

    onCollectionUpdate = () => {
        this.ref.doc("Rushees").get().then(doc => {
            if (!this._isMounted) { return }
            this.setState({ gtids: Object.keys(doc.data()) });
            Object.values(doc.data()).forEach(rushee => {
                if (this.props.match.params.gtid === rushee.gtid) {
                    if (this.state.picture == null) {
                        this.storageRef.child(rushee.pictureID).getDownloadURL()
                            .then(url => {
                                if (!this._isMounted) { return }
                                const xhr = new XMLHttpRequest();
                                xhr.responseType = 'blob';
                                xhr.onload = () => {
                                    if (!this._isMounted) { return }
                                    this.setState({picture: xhr.response})
                                };
                                xhr.open('GET', url);
                                xhr.send();
                            });
                    }
                    let newState = {};
                    for (const key in rushee) {
                        newState[key] = rushee[key];
                    }
                    newState.loading = false;
                    newState.oldgtid = newState.gtid;
                    newState.oldFirst = newState.firstName;
                    newState.oldLast = newState.lastName;
                    newState.am = newState.am ? String(newState.am) : "";
                    this.setState(newState);
                    M.AutoInit();
                }
            });
        });
    };

    componentDidMount() {
        this._isMounted = true;
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    render() {
        return (
            <div>
                <Navbar config={{
                    home: true,
                    back: true,
                    backPage: `${this.state.oldgtid}/${Page.VIEWRUSHEE}`
                }}/>
                {this.state.loading ? <Loading/> :
                    <div>
                        <div className="container">
                            <h2 className="header center blue-text">Edit Rushee</h2>
                        </div>
                        <div className="container">
                            <form className="row">
                                <div className="input-field col s6 blue-input-field">
                                    <select id="bidStatus" value={this.state.bidStatus} onChange={this.updateInfo}>
                                        <option value={BidStatus.NONE}>None</option>
                                        <option value={BidStatus.VOTED}>Voted</option>
                                        <option value={BidStatus.RECEIVED}>Received</option>
                                        <option value={BidStatus.ACCEPTED}>Accepted</option>
                                        <option value={BidStatus.ELSEWHERE}>Elsewhere</option>
                                        <option value={BidStatus.TALK}>The Talk</option>
                                    </select>
                                    <label>Bid Status</label>
                                </div>
                                <div className="input-field col s6 blue-input-field">
                                    <input id="am" type="text" disabled={this.state.bidStatus !== "accepted"} value={this.state.am ? this.state.am : ""} onChange={this.updateInfo}/>
                                    <label htmlFor="am" className={this.state.am ? "active" : null}>AM</label>
                                </div>
                                <div className="input-field col s12 blue-input-field">
                                    <input id="contact" type="text" value={this.state.contact} onChange={this.updateInfo}/>
                                    <label htmlFor="contact" className={this.state.contact ? "active" : null}>Contact</label>
                                </div>
                                <div className="input-field col s6 blue-input-field">
                                    <input id="firstName" type="text" className="validate" pattern=".{1,}" required value={this.state.firstName} onChange={this.updateInfo}/>
                                    <label htmlFor="firstName" className={this.state.firstName ? "active" : null}>First</label>
                                    <span className="helper-text" data-error="Can not be empty"/>
                                </div>
                                <div className="input-field col s6 blue-input-field">
                                    <input id="lastName" type="text" className="validate" pattern=".{1,}" required value={this.state.lastName} onChange={this.updateInfo}/>
                                    <label htmlFor="lastName" className={this.state.lastName ? "active" : null}>Last</label>
                                    <span className="helper-text" data-error="Can not be empty"/>
                                </div>
                                <div className="input-field col s6 blue-input-field">
                                    <select id="year" value={this.state.year} onChange={this.updateInfo}>
                                        <option value="1st">1st Year</option>
                                        <option value="2nd">2nd Year</option>
                                        <option value="3rd">3rd Year</option>
                                        <option value="4th">4th Year</option>
                                    </select>
                                    <label>Year</label>
                                </div>
                                <div className="input-field col s6 blue-input-field">
                                    <input id="major" type="text" className="validate" pattern=".{1,}" required value={this.state.major} onChange={this.updateInfo}/>
                                    <label htmlFor="major" className={this.state.major ? "active" : null}>Major</label>
                                    <span className="helper-text" data-error="Can not be empty"/>
                                </div>
                                <div className="input-field col s6 blue-input-field">
                                    <input id="gtid" type="text" className="validate" pattern=".{9}" required value={this.state.gtid} onChange={this.updateInfo}/>
                                    <label htmlFor="gtid" className={this.state.gtid ? "active" : null}>GTID</label>
                                    <span className="helper-text" data-error="Must be a 9-digit number"/>
                                </div>
                                <div className="input-field col s6 blue-input-field">
                                    <input id="phoneNumber" type="text" className="validate" pattern=".{10}" required value={this.state.phoneNumber} onChange={this.updateInfo}/>
                                    <label htmlFor="phoneNumber" className={this.state.phoneNumber ? "active" : null}>Phone Number</label>
                                    <span className="helper-text" data-error="Must be a 10-digit number"/>
                                </div>
                                <div className="input-field col s6 blue-input-field">
                                    <input id="city" type="text" className="validate" pattern=".{1,}" required value={this.state.city} onChange={this.updateInfo}/>
                                    <label htmlFor="city" className={this.state.city ? "active" : null}>City</label>
                                    <span className="helper-text" data-error="Can not be empty"/>
                                </div>
                                <div className="input-field col s6 blue-input-field">
                                    <input id="state" type="text" className="validate" pattern=".{1,}" required value={this.state.state} onChange={this.updateInfo}/>
                                    <label htmlFor="state" className={this.state.state ? "active" : null}>State</label>
                                    <span className="helper-text" data-error="Can not be empty"/>
                                </div>
                                <div className="file-field input-field col s12 blue-input-field" onChange={this.updateInfo}>
                                    <div className="btn blue">
                                        <span>Picture</span>
                                        <input type="file" id="pictureID"/>
                                    </div>
                                    <div className="file-path-wrapper blue-input-field">
                                        <input className="file-path" type="text" value={this.state.pictureID} onChange={this.updateInfo} placeholder="Upload Picture"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="center">
                            <button className="waves-effect waves-light btn blue" style={{ "marginBottom": "20px" }} onClick={this.updateRushee}>Save Changes</button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default EditRushee;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UnicornLogo from '../assets/Unicorn.png';
import Page from "../util/Page";
import M from "materialize-css";

/**
 * Top Navbar for pages
 * Customizable through props
 * Includes Fixed Action Button at bottom right of screen
 */
class Navbar extends Component {

    componentDidMount() {
        M.AutoInit();
    }

    render() {
        const rushee = this.props.rushee;
        return (
            <div>
                {/* ADD RUSHEE */}
                {this.props.config.addRushee &&
                    <div className="fixed-action-btn">
                        <Link to={`/${Page.ADDRUSHEE}`} className="btn-floating btn-large blue">
                            <i className="large material-icons">add</i>
                        </Link>
                    </div>
                }
                <nav className="blue" role="navigation">
                    <div className="nav-wrapper container">
                        {/* EMPTY WITH JUST LOGO */}
                        {this.props.config.empty &&
                            <div className="brand-logo-image-link">
                                <img src={UnicornLogo} alt="Unicorn" className="brand-logo-image"/>
                            </div>
                        }
                        {/* HOME LOGO */}
                        {this.props.config.home &&
                            <Link to={`/${Page.HOMEPAGE}`} className="brand-logo-image-link">
                                <img src={UnicornLogo} alt="Unicorn" className="brand-logo-image"/>
                            </Link>
                        }
                        {/* RIGHT SIDE OF NAV */}
                        <div className="nav-right">
                            {/* FILTER ON RIGHT */}
                            {this.props.config.filter &&
                                <ul className="nav-icon-right">
                                    <a className="modal-trigger" href="#filter">
                                        <i className="material-icons right">filter_list</i>
                                    </a>
                                </ul>
                            }
                            {/* EDIT ON RIGHT */}
                            {this.props.config.edit &&
                                <ul className="nav-icon-right">
                                    <Link to={{
                                        pathname: `/${rushee.gtid}/${Page.EDITRUSHEE}`,
                                        state: { rushee: rushee }
                                    }}>
                                        <i className="material-icons right">edit</i>
                                    </Link>
                                </ul>
                            }
                            {/* SORT ON LEFT */}
                            {this.props.config.sort &&
                                <ul className="nav-icon-left">
                                    <a className="modal-trigger" href="#sort">
                                        <i className="material-icons right">sort</i>
                                    </a>
                                </ul>
                            }
                            {/* ADD EVENT ON LEFT */}
                            {this.props.config.addEvent &&
                                <ul className="nav-icon-left">
                                    <Link to={ `/${rushee.gtid}/${Page.ADDEVENT}` }>
                                        <i className="material-icons right">add</i>
                                    </Link>
                                </ul>
                            }
                        </div>
                        {/* LEFT SIDE OF NAV */}
                        <div className="nav-left">
                            {/* SEARCH ON LEFT */}
                            {this.props.config.search &&
                                <ul className="nav-icon-left">
                                    <a className="modal-trigger" href="#search">
                                        <i className="material-icons left">search</i>
                                    </a>
                                </ul>
                            }
                            {/* BACK ON LEFT */}
                            {this.props.config.back &&
                            <ul className="nav-icon-left">
                                <Link to={ `/${this.props.config.backPage}` }>
                                    <i className="material-icons left">arrow_back</i>
                                </Link>
                            </ul>
                            }
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;